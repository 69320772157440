import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import("../components/Header")
    component: () => import("../views/Home"),
    meta: {
      title: "FMDATA-最好的球员数据网站"
    }
  },
  {
    path: "/login",
    name: 'Login',
    component: () => import("../views/Login"),
    meta: {
      title: "用户登录 - FMDATA"
    }
  },
  {
    path: "/register",
    name: 'Register',
    component: () => import("../views/Register"),
    meta: {
      title: "用户注册 - FMDATA"
    }
  },
    // 球员
  {
    path: '/sportsman',
    name: 'Sportsman',
    component: () => import("../views/sportsman/Sportsman"),
    meta: {
      title: "球员数据 - FMDATA"
    }
  },
  {
    path: '/sportsman_detail',
    name: 'SportsmanDetail',
    component: () => import("../views/sportsman/SportsmanDetail"),
    meta: {
      title: "球员详情 - FMDATA"
    }
  },
  {
    path: '/sportsman_detail_release',
    name: 'SportsmanDetailRelease',
    component: () => import("../views/sportsman/SportsmanDetailRelease"),
    meta: {
      title: "球员数据发布 - FMDATA"
    }
  },
  {
    path: '/sportsman_detail_edit',
    name: 'SportsmanDetailEdit',
    component: () => import("../views/sportsman/SportsmanDetailEdit"),
    meta: {
      title: "球员数据编辑 - FMDATA"
    }
  },
    // 球队
  {
    path: '/team',
    name: 'Team',
    component: () => import("../views/team/Team"),
    meta: {
      title: "球队 - FMDATA"
    }
  },
  {
    path: '/team_detail',
    name: 'TeamDetail',
    component: () => import("../views/team/TeamDetail"),
    meta: {
      title: "球队详情 - FMDATA"
    }
  },
    // 最佳
  {
    path: '/optimal',
    name: 'Optimal',
    component: () => import("../views/optimal/Optimal"),
    meta: {
      title: "最佳 - FMDATA"
    }
  },
  {
    path: '/optimal_detail',
    name: 'OptimalDetail',
    component: () => import("../views/optimal/OptimalDetail"),
    meta: {
      title: "最佳详情 - FMDATA"
    }
  },
    // 数据
  {
    path: '/data',
    name: 'Data',
    component: () => import("../views/data/Data"),
    meta: {
      title: "数据 - FMDATA"
    }
  },
  {
    path: '/data_detail',
    name: 'DataDetail',
    component: () => import("../views/data/DataDetail"),
    meta: {
      title: "数据详情 - FMDATA"
    }
  },
    // 皮肤
  {
    path: '/skin',
    name: 'Skin',
    component: () => import("../views/skin/Skin"),
    meta: {
      title: "皮肤 - FMDATA"
    }
  },
  {
    path: '/skin_detail',
    name: 'SkinDetail',
    component: () => import("../views/skin/SkinDetail"),
    meta: {
      title: "皮肤详情 - FMDATA"
    }
  },
    // 战术
  {
    path: '/tactics',
    name: 'Tactics',
    component: () => import("../views/tactics/Tactics"),
    meta: {
      title: "战术 - FMDATA"
    }
  },
  {
    path: '/tactics_detail',
    name: 'TacticsDetail',
    component: () => import("../views/tactics/TacticsDetail"),
    meta: {
      title: "战术详情 - FMDATA"
    }
  },
  // 视频
  {
    path: '/video',
    name: 'Video',
    component: () => import("../views/video/Video"),
    meta: {
      title: "数据下载 - FMDATA"
    }
  },
  // 视频详情
  {
    path: '/video_detail',
    name: 'VideoDetail',
    component: () => import("../views/video/VideoDetail"),
    meta: {
      title: "文章编辑 - FMDATA"
    }
  },
  // 文章
  {
    path: '/article',
    name: 'Article',
    component: () => import("../views/article/Article"),
    meta: {
      title: "数据下载 - FMDATA"
    }
  },
  // 文章详情
  {
    path: '/article_detail',
    name: 'ArticleDetail',
    component: () => import("../views/article/ArticleDetail"),
    meta: {
      title: "文章编辑 - FMDATA"
    }
  },
    // 我的数据
  {
    path: '/info',
    name: 'Info',
    component: () => import("../views/personal_center/Info"),
    meta: {
      title: "我的信息 - FMDATA"
    }
  },
  {
    path: '/my_data',
    name: 'MyData',
    component: () => import("../views/personal_center/MyData"),
    meta: {
      title: "我的数据 - FMDATA"
    }
  },
    // 发布页
  {
    path: '/publish_data',
    name: 'PublishData',
    component: () => import("../views/PublishData"),
    meta: {
      title: "数据发布 - FMDATA"
    }
  },
    // 综合发布
  {
    path: '/complex_release',
    name: 'ComplexRelease',
    component: () => import("../views/complex/Release"),
    meta: {
      title: "综合数据发布 - FMDATA"
    }
  },
    // 综合编辑
  {
    path: '/complex_edit',
    name: 'ComplexEdit',
    component: () => import("../views/complex/Edit"),
    meta: {
      title: "综合数据编辑 - FMDATA"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
